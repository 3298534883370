import React, { useContext } from "react";
import { KeyedMutator } from "swr";

import { AlertNetwork } from "../components/AlertNetwork";
import { Organization } from "../models";
import { UserContext } from "./UserContext";
import { useBackend } from "../hooks/useBackend";

export type OrganizationState = {
  data?: Organization[];
};

type Dispatch = null | {
  mutate: KeyedMutator<Organization[]>;
};

const initialStateFactory = (initialState?: Partial<OrganizationState>): OrganizationState => ({
  data: undefined,
  ...initialState,
});

const OrganizationStateContext = React.createContext<OrganizationState>(initialStateFactory());
const OrganizationsDispatchContext = React.createContext<Dispatch>(null);

export const OrganizationProvider: React.FC<React.PropsWithChildren<{ initialState?: Partial<OrganizationState> }>> = (
  props
) => {
  /** ログアウトすると企業情報はリセットされる => undefined */
  const { userState } = useContext(UserContext);
  const { user } = userState;
  // include_plan: https://admin.rimo.app/organizations/:teamID/payment_histories で使ってる
  const { data, error, mutate } = useBackend<Organization[]>(user && "/organizations?include_plan=true", {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return (
    <OrganizationStateContext.Provider value={{ data }}>
      <OrganizationsDispatchContext.Provider value={{ mutate }}>{props.children}</OrganizationsDispatchContext.Provider>
      <AlertNetwork error={Boolean(error)} title="企業情報" />
    </OrganizationStateContext.Provider>
  );
};

function useOrganizationState() {
  return useContext(OrganizationStateContext);
}

export function useOrganizationDispatch() {
  const context = useContext(OrganizationsDispatchContext);
  if (!context) throw new Error("should load dispatch context");
  return context;
}

export function useOrganizations() {
  const state = useOrganizationState();
  return state.data;
}

export const AppEnv = process.env.NEXT_PUBLIC_APP_ENV || "development";

export const SentryHost = process.env.NEXT_PUBLIC_SENTRY_HOST;

export const GitCommitSHA = process.env.NEXT_PUBLIC_GIT_COMMIT_SHA || "";

export const RimoRtcHost = process.env.NEXT_PUBLIC_RIMO_RTC_HOST;
export const RimoWebsocketUrl = process.env.NEXT_PUBLIC_RIMO_WEBSOCKET_URL;
export const RimoBackendUrl = process.env.NEXT_PUBLIC_RIMO_BACKEND_URL;
export const RimoImageServerUrl = process.env.NEXT_PUBLIC_RIMO_IMAGE_SERVER_URL;
export const RimoTurnUrl = process.env.NEXT_PUBLIC_RIMO_TURN_URL;
export const RimoTurnUsername = process.env.NEXT_PUBLIC_RIMO_TURN_USERNAME;
export const RimoTurnPassword = process.env.NEXT_PUBLIC_RIMO_TURN_PASSWORD;

export const FirebaseApiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FirebaseAuthDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const FirebaseDatabaseUrl = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
export const FirebaseProjectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const FirebaseStorageBucket = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const FirebaseMessagingSenderId = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const FirebaseAppId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const FirebaseMeasurementId = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

export const GoogleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const GoogleTagManagerId = process.env.GOOGLE_TAG_MANAGER_ID || "GTM-M3FRS9L";
export const GoogleAwConversionId = process.env.NEXT_PUBLIC_GOOGLE_AW_CONVERSION_ID;
export const GoogleFormConversionSendTo = process.env.NEXT_PUBLIC_GOOGLE_FORM_CONVERSION_SEND_TO;
export const YahooYssConversionId = process.env.NEXT_PUBLIC_YAHOO_YSS_CONVERSION_ID;
export const YahooYssConversionLabel = process.env.NEXT_PUBLIC_YAHOO_YSS_CONVERSION_LABEL;
export const YahooYssFormConversionLabel = process.env.NEXT_PUBLIC_YAHOO_YSS_FORM_CONVERSION_LABEL;
export const YahooYdnConversionId = process.env.NEXT_PUBLIC_YAHOO_YDN_CONVERSION_ID;
export const YahooYdnConversionLabel = process.env.NEXT_PUBLIC_YAHOO_YDN_CONVERSION_LABEL;
export const YahooRetargetingID = process.env.NEXT_PUBLIC_YAHOO_RETARGETING_ID;

export const FacebookPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const StripePublishableKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "";

export const ReportCallQualityApiUrl = process.env.NEXT_PUBLIC_REPORT_CALL_QUALITY_URL;

export const AboutNewEditorUrl = "https://guide.rimo.app/ja/articles/8869688";

export const LDClientSideID = process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID || "";
export const LDRelayUrl = process.env.NEXT_PUBLIC_LD_RELAY_URL || "";

export const ZoomClientId = process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID ?? "";

export const AffiliateProgramID = process.env.NEXT_PUBLIC_AFFILIATE_A8_PROGRAM_ID ?? "s00000023714001";

export const SlackClientId = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID || "";
export const SlackScope = process.env.NEXT_PUBLIC_SLACK_SCOPE || "";
export const SlackRedirectUri = process.env.NEXT_PUBLIC_SLACK_REDIRECT_URI || "";

export const SalesforceClientId = process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID || "";

export const StripeBillingServiceUrl = process.env.NEXT_PUBLIC_STRIPE_BILLING_SERVICE_URL;
